
import { defineComponent, toRefs } from "vue";

export default defineComponent({
  props: ["form"],

  setup(props) {
    const userRoleOptions = [
      { id: 1, name: "Admin" },
      { id: 2, name: "User" },
    ];
    const { model, errors } = toRefs(props.form.state);

    return {
      userRoleOptions,
      model,
      errors,
    };
  },
});
